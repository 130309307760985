import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import BezierEasing from 'bezier-easing'

import Layout from '../components/layout'
import SEO from '../components/single-blog/seo'
import NewsletterForm from '../components/newsletter-form/index.js'

const Signup = ({ data, pageContext }) => {
  const contentAnim = useSpring({
    opacity: 1,
    from: {
      opacity: 0
    },
    config: {
      duration: 600,
      easing: BezierEasing(0.165, 0.84, 0.44, 1.0)
    },
    delay: 200
  })

  return (
    <Layout>
      <SEO pageTitle={pageContext.title} slug={pageContext.slug} {...data.wordPress.pageBy.seo} />
      <WrapSignup>
        <NewsletterOuter className="active">
          <WrapNewsletterContent style={contentAnim}>
            <SignUpTitle>{data.wordPress.globalOptions.optionFields.newsletter.title}</SignUpTitle>
            <NewsletterForm
              content={data.wordPress.globalOptions.optionFields.newsletter}
              fromSignup
            />
          </WrapNewsletterContent>
        </NewsletterOuter>
      </WrapSignup>
    </Layout>
  )
}

const WrapSignup = styled.div`
  position: relative;
  padding: 20rem 12.5% 65px 12.5%;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  z-index: 100;
  background-color: ${props => props.theme.colours.white};

  @media (max-width: 1260px) {
    padding: 0 12.5% 65px 12.5%;
  }

  @media (max-width: 1024px) {
    padding: 15rem 10% 10rem 10%;
    width: 90%;
  }

  @media (max-width: 650px) {
    width: 100%;
    padding: 10rem 2.5rem 65px 2.5rem;
  }

  li,
  span,
  b {
    color: ${props => props.theme.colours.darkGrey};
    font-family: ${props => props.theme.fonts.circular};
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${props => props.theme.colours.pink};
  }

  h1 {
    font-size: 5rem;
    padding: 2rem 0;
  }

  h2 {
    font-size: 4rem;
    padding-top: 2rem;
  }

  h3 {
    font-size: 3.5rem;
    padding-top: 2rem;
  }

  h4 {
    font-size: 3rem;
    padding-top: 2rem;
  }

  span {
    font-size: 1.5rem;
    font-family: ${props => props.theme.fonts.circular};
    line-height: 1.5;
    font-weight: 300;

    @media (max-width: 650px) {
      font-size: 2rem;
    }
  }
`

const NewsletterOuter = styled.aside`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  pointer-events: none;
  z-index: 121;

  &.active {
    pointer-events: all;
  }
`

const WrapNewsletterContent = styled(animated.div)`
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 650px) {
    width: 100%;
  }

  form {
    input {
      color: ${props => props.theme.colours.darkGrey};
      border-bottom: 1px solid ${props => props.theme.colours.darkGrey};

      &::placeholder {
        font-family: ${props => props.theme.fonts.circular};
        font-size: 2rem;
        color: ${props => props.theme.colours.grey};
        opacity: 1;

        @media (max-width: 1024px) {
          font-size: 16px;
        }
      }
    }

    button[type='submit'] {
      color: ${props => props.theme.colours.grey};
      border-color: ${props => props.theme.colours.pink};

      &:hover {
        background-color: ${props => props.theme.colours.pink};
        color: ${props => props.theme.colours.white};
      }
    }

    p {
      color: ${props => props.theme.colours.darkGrey};

      a {
        color: ${props => props.theme.colours.pink};
      }
    }
  }
`

const SignUpTitle = styled.h5`
  font-family: ${props => props.theme.fonts.sofia};
  color: rgba(0, 0, 0, 0.25);
  font-size: 5.7rem;
  text-align: center;
  width: 150%;
  margin: 0 -25%;

  @media (max-width: 1360px) {
    font-size: 5rem;
  }

  @media (max-width: 1024px) {
    width: 100%;
    margin: 0;
  }
`

export default Signup

export const query = graphql`
  query($wordpressId: Int!) {
    wordPress {
      globalOptions {
        optionFields {
          newsletter {
            contactInfos
            title
          }
        }
      }
      pageBy(pageId: $wordpressId) {
        ...SeoFragment
      }
    }
  }
`
